import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {SAK_PATH} from "../../../constants/urls";
import {Button, makeStyles, useTheme} from "@material-ui/core";
import ButtonWithAction from "../../common/buttons/ButtonWithAction";
import {Add, Close, Remove} from "@material-ui/icons";
import styles from "./confirmSaveSakDialog.module.css";
import {CONFLICT_TYPE} from "../../../constants/enum";

type Props = {
    konflikt: Konflikt,
    formData: Sak,
    onKonfliktAbort: () => void,
};

const useStyles = makeStyles((theme) => ({
    dialogContentText: {
        textAlign: "left"
    },
    dialogContentIntro: {
    },
    sakerContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: 10,
    },
    expandCollapsButton: {
        color: theme.palette.secondary.main,
        alignItems: "center",
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        // paddingBottom: 20,
    },
    dialogContentConfirm: {
        marginTop: 10,
    },

}));

const ConfirmSaveSakDialog = (props: Props) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false)
    const classes = useStyles(theme);
    const maxSaklinkShown = 3;


    function toggleExpand (){
        setExpanded(!expanded)
    }

    function handleConfirmLocal() {
        // handled in form's submit
    }

    function handleAbortLocal() {
        props.onKonfliktAbort();
    }

    function truncString(str: string | undefined, maxLength: number){
        if (str === undefined || str.length <= maxLength) {
            return str;
        }
        return str.substring(0, maxLength) + " ...";
    }

    function getSakTag(id: number) {
        return <a href={SAK_PATH.replace(":id", "" + id)} target="_blank" style={{ marginLeft: '4px'}}>{id}</a>;
    }

    function delimitTags(ellipsedTags: React.ReactNode[], delimiter: any) {
        return ellipsedTags.map((tag, index) => {
                return index < ellipsedTags.length - 1 ? [tag, delimiter] : tag;
            }
        );
    }

    function getEllipsedTags(tags: React.JSX.Element[], isExpanded: boolean) {
        return tags.reduce<React.ReactNode[]>((acc, tag, index) => {

            if (isExpanded) {
                acc.push(tag);

            } else {
                if (index < maxSaklinkShown) {
                    acc.push(tag);
                }
                if (index === maxSaklinkShown && tags.length > maxSaklinkShown) {
                    acc.push("...");
                }
            }
            return acc;
        }, []);
    }

    function getSakLinks() {
        const sakTags = props.konflikt.ids.map(id => getSakTag(id));
        const ellipsedTags = getEllipsedTags(sakTags, expanded);
        return delimitTags(ellipsedTags, ", ");
    }

    function getDuplikatLinkMsg() {

        const link = props.formData.lenke;
        const trucLink = truncString(props.formData.lenke, 35);
        const denne = denneBestemtForm(props.konflikt.ids.length);
        const n = nOrBlank(props.konflikt.ids.length);
        const saken = sakBestemtForm(props.konflikt.ids.length);
        const sakTag = `<a href='${link}' target=\"_blank\">${trucLink}</a>`;

        return `Lenke til kildeinformasjon: ${sakTag} benyttes allererde i ${denne} ${n} ${saken}:`;
    }

    function getDuplikatInfoMsg() {
        const fields = "Tittel, Sammendrag";
        const denne = denneBestemtForm(props.konflikt.ids.length);
        const n = nOrBlank(props.konflikt.ids.length);
        const saken = sakBestemtForm(props.konflikt.ids.length);

        return `Duplikat informasjon (${fields}) funnet i ${denne} ${n} ${saken}:`;
    }

    function denneBestemtForm(n :number) {
        return n > 1 ? "disse" : "denne";
    }

    function sakBestemtForm(n: number) {
        return n > 1 ? "sakene" : "saken";
    }

    function nOrBlank(n :number) {
        return n > 1 ? n + " " : "";
    }

        return (
        <Dialog
            open={true}
            onClose={() => {
                return;
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

            // render submit button within the <form> element in the DOM:
            container={document.querySelector('form')}
        >
            <DialogTitle id="alert-dialog-title">
                Duplikatkontroll
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{justifyContent: "left"}}>
                    <div className={classes.dialogContentText}>
                        <div className={classes.dialogContentIntro}>
                            {
                                props.konflikt.type === CONFLICT_TYPE.DUPLICATE_SAK ?
                                    getDuplikatInfoMsg() :
                                    <span dangerouslySetInnerHTML={{__html: getDuplikatLinkMsg()}}/>
                            }
                        </div>

                        <div className={classes.sakerContainer}>
                            {getSakLinks()}
                        </div>

                        {props.konflikt.ids.length > maxSaklinkShown && (
                            <Button
                                startIcon={expanded ? <Remove/> : <Add/>}
                                onClick={toggleExpand}
                                className={classes.expandCollapsButton}
                            >{expanded ? "Se færre" : "Se alle"}
                            </Button>
                        )}

                        <div className={classes.dialogContentConfirm}>
                            Bekreft opprett sak
                            med {props.konflikt.type === CONFLICT_TYPE.DUPLICATE_SAK ? "duplikat info" : "duplikat lenke"}
                        </div>
                    </div>
                </DialogContentText> </DialogContent>
            <DialogActions className={styles.actionButtons}>
                <ButtonWithAction
                    title={"Avbryt"}
                    color={theme.palette.grey["300"]}
                    onClick={handleAbortLocal}
                    icon={<Close/>}
                />
                <ButtonWithAction
                    title={"Bekreft duplikat"}
                    color={theme.palette.secondary.main}
                    onClick={handleConfirmLocal}
                    isSubmit={true}
                />
            </DialogActions>
        </Dialog>
        );
};

export default ConfirmSaveSakDialog;
