import React, { useState } from "react";
import { UseKoordinatorContext } from "../../../context/KoordinatorContext";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory, useParams } from "react-router-dom";
import { API_SAKER_PATH, BASE_PATH, ERROR_PATH } from "../../../constants/urls";
import {useMediaQuery, useTheme} from "@material-ui/core";
import "../../../styles/App.css";
import ButtonWithAction from "../../common/buttons/ButtonWithAction";
import { Close, DeleteOutline } from "@material-ui/icons";
import styles from "./deleteSakAlert.module.css";
import {getDefaultQuery} from "../../../utils/utils";

const DeleteSakAlert = () => {
    const { id } = useParams<{ id: string }>();
    const [open, setOpen] = useState(false);
    const token = UseKoordinatorContext().token;
    const history = useHistory();
    const [error, setError] = useState(false);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteSak = () => {
        fetch(API_SAKER_PATH + id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(res.statusText);
                }
            })
            .then(() => {
                history.push(BASE_PATH + getDefaultQuery());
            })
            .catch((error) => {
                setError(true);
                console.log(error);
            });
        setOpen(false);
    };

    if (error) {
        history.push(ERROR_PATH);
    }

    return (
        <div>
            <ButtonWithAction
                title={isMobileScreen ? "Slett" : "Slett saken"}
                color={theme.palette.info.light}
                onClick={handleClickOpen}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        boxShadow: "none",
                    },
                }}
            >
                <DialogTitle>{"Helt sikker?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Saken kan ikke gjenopprettes ved sletting. Er du helt
                        sikker på at du ønsker å slette saken?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={styles.actionButtons}>
                    <ButtonWithAction
                        title={"Avbryt"}
                        color={theme.palette.grey["600"]}
                        onClick={handleClose}
                        icon={<Close />}
                    />
                    <ButtonWithAction
                        title={"Slett"}
                        color={theme.palette.info.light}
                        onClick={handleDeleteSak}
                        icon={<DeleteOutline />}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeleteSakAlert;
