import React from "react";
import "../../../styles/App.css";
import styles from "./pageNotFound.module.css";
import dodo from "./dodo.svg";

function PageNotFound() {
    return (
        <div className={styles.content}>
            <h1 className="main-heading">
                Beklager, denne siden er utryddet...
            </h1>
            <p className={styles.subtitle}>Prøv igjen om en liten stund</p>
            <img src={dodo} alt="Dodo" />
        </div>
    );
}

export default PageNotFound;
