import React from "react";
import Chip from "@material-ui/core/Chip";
import styles from "./staticSearchChips.module.css";
import {useNameMapping} from "../../../context/NameMappingContext";
import {AlleSaksnivaa} from "../../../constants/Saksnivaa";
import {AlleSvarfrist} from "../../../constants/Svarfrist";
import SakFilter from "../SakFilter";

type Props = {
    filter: SakFilter;
    fritekstErrorMsg?: string;
};

function StaticSearchChips(props: Props) {

    const nameMapping = useNameMapping();

    return (
        <div>
        <div className={styles.chipsRoot}>

            {(props.filter.getFritekst() != null) && (
                // <div>
                <Chip key={42} label={"\"" + props.filter.getFritekst() + "\""}
                      variant="outlined"
                      clickable={true}
                      className={props.fritekstErrorMsg === undefined ? styles.filterChip : styles.filterChipError}
                />
                // </div>
            )}
            {AlleSvarfrist.filter((frist) => { return props.filter.getSvarfrist().includes(frist.paramValue) }).map((frist) => {
                return (
                    <Chip key={frist.paramValue} label={frist.labelChip} variant="default" clickable={true} className={styles.filterChip}/>
                );
            })
            }
            {AlleSaksnivaa.filter((nivaa) => { return props.filter.getSaksnivaa().includes(nivaa.paramValue) }).map((nivaa) => {
                return (
                    <Chip key={nivaa.paramValue} label={nivaa.labelChip} variant="default" clickable={true} className={styles.filterChip}/>
                );
            })
            }
            {nameMapping.mapFylkenavn(props.filter.getFylker()).sort().map((navn) => {
                return (<Chip label={navn} className={styles.filterChip}/>);
            })
            }
            {nameMapping.mapKommunenavn(props.filter.getKommuner()).sort().map((navn) => {
                return (<Chip label={navn} className={styles.filterChip}/>);
            })
            }
        </div>
            {props.filter.getFritekst() != null && (
                <p className={styles.errorMsg}>{props.fritekstErrorMsg}</p>
            )}
        </div>
    );
}

export default StaticSearchChips;
