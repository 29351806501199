import React, {FormEvent, useEffect, useRef, useState} from "react";
import {
    Button,
    Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputAdornment,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    useTheme,
} from "@material-ui/core";
import {sakStatus} from "../../../constants/enum";
import Switch from "@material-ui/core/Switch";
import {Autocomplete} from "@material-ui/lab";
import {sakTypes} from "../../../constants/sakTypes";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {emptySak} from "../../../constants/initializedTypes";
import styles from "./sakForm.module.css";
import {ErrorOutline, HelpOutline} from "@material-ui/icons";
import TextEditor, {isEmptyWhitespaceOrHtmlTagsOnly} from "../textEditor/TextEditor";
import {useNameMapping} from "../../../context/NameMappingContext";
import {Prompt} from "react-router-dom";
import {isEqualArrays} from "../../../utils/utils";
import {DeltaStatic, Sources} from "quill";
import {UnprivilegedEditor} from "react-quill";
import ConfirmSaveSakDialog from "../confirmSaveSakDialog/ConfirmSaveSakDialog";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        // backgroundColor: "lightgrey",
        maxWidth: theme.breakpoints.values.lg + "px",
    },

    inputField: {
        width: "100%",
        // maxWidth: "500px",
    },
    sammendrag: {
        backgroundColor: "lightblue",
        width: "100%",
        // maxWidth: "500px",
        maxHeight: "409px",
        margin: "44px 0 32px 0",
    },
    buttonSubmit: {
        backgroundColor: "var(--blue)",
        color: "var(--white)",
        borderRadius: "40px",
        padding: "6px 16px",
        maxHeight: "40px",
        boxShadow: "none",
        marginLeft: "auto",
        marginBottom: "24px",
        textTransform: "none",
        "&:hover, &:focus": {
            boxShadow: "none",
            backgroundColor: "var(--blue)",
        },
    },
    labelStatus: {
        fontSize: "14px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        marginLeft: "4px",
        fontSize: "20px",
    },
}));

type Props = {
    sak: Sak;
    handleSubmit: (e: FormEvent<HTMLFormElement>, formData: Sak, konfliktConfirmed?: boolean) => void;
    isEditSak: boolean;
    konflikt?: Konflikt;
    onKonfliktAbort?: () => void,
    multiSakPrepareNewNr?: number,
};

type FormErrors = {
    tittel?: string;
    sammendrag?: string;
    omrade?: string;
    areal?: string;
    lenke?: string;
};

const SakForm = (props: Props) => {

    const nameMapping = useNameMapping();

    const [formData, setFormData] = useState<Sak>(props.sak);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [errors, setErrors] = useState<FormErrors>({});
    const [submitCount, setSubmitCount] = useState(0);
    const [isFormDirty, setIsFormDirty] = useState(false)
    const theme = useTheme();
    const classes = useStyles(theme);

    const tittelInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        // console.log("useEffect [isFormDirty: ", isFormDirty, "]")
        // console.log("  - addEventListener beforeunload to window (isFormDirty:", isFormDirty, ")")
        window.addEventListener("beforeunload", onBeforeUnload);

        return () => {
            //clean up
            // console.log("  - removeEventListener beforeunload to window (isFormDirty:", isFormDirty, ")")
            window.removeEventListener("beforeunload", onBeforeUnload);
        };

    }, [isFormDirty]);

    // updates list of kommuner in dropdown to selected fylkes kommuner
    useEffect(() => {
        // console.log("useEffect [formData.fylker: ", formData.fylker, "]")
        if (!nameMapping.isLoading) {
            filterAndSetKommuner(nameMapping.getKommunerForFylkeIds(formData.fylker), formData.kommuner);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.fylker]);

    useEffect(() => {
        // console.log("useEffect [formData.nasjonal: ", formData.nasjonal, "]")
        if (formData.nasjonal) {
            clearFylkeAndKommuneInFormData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.nasjonal]);

    useEffect(() => {
        // console.log("useEffect [formData: ", formData, "]...")
        if (submitCount > 0) {
            validateForm();
        }
        if (props.multiSakPrepareNewNr) {
            if (isEmptySak(formData)) {
                setIsFormDirty(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        // console.log("useEffect [prepareNew: ", props.prepareNew, "]")

        if (props.multiSakPrepareNewNr) {
            setSubmitCount(0)

            setFormData(emptySak)

            setTimeout(() => {
                tittelInputRef.current?.focus(); // to set focus after rendering
            }, 0);
        }
    }, [props.multiSakPrepareNewNr]);


    function isEmptySak(form: Sak) {

        return form.id === ""
            && form.tittel === ""
            && form.status === sakStatus.horing
            && !form.foelgesOpp
            && form.fylker.length == 0
            && form.kommuner?.length == 0
            && form.sammendrag === ""
            && !form.nasjonal

            && form.frist === undefined
            && form.areal === undefined
            && form.sakstype === undefined
            && form.saksnummer === undefined
            && form.myndighet === undefined
            && form.lenke === undefined
            && form.opprettet === undefined
            && form.oppdatert === undefined
            && form.vedlegg === undefined;
    }

    function clearFylkeAndKommuneInFormData() {
        setFormData({
            ...formData,
            fylker: [],
            kommuner: [],
        });
    }

    function validateForm() {
        // console.log("validateForm...")
        const error: FormErrors = {};
        if (!formData.tittel) {
            error.tittel = "Husk å fylle inn sakens tittel";
        }
        if (formData.tittel.length > 1000) {
            error.tittel = "Tittel kan ikke inneholde mer enn 1000 tegn";
        }
        if (formData.areal && formData.areal > 450000000) {
            error.areal =
                "Sakens berørete areal kan ikke være større enn Norge og Svaldbard til sammen";
        }
        if (formData.lenke && !validateUrl(formData.lenke)) {
            error.lenke = "Lenken må være en gyldig nettadresse";
        }
        if (isEmptyWhitespaceOrHtmlTagsOnly(formData.sammendrag)) {
            error.sammendrag = "Husk å skrive et sammendrag av saken";
        }
        if (formData.sammendrag.length > 8000) {
            error.sammendrag =
                "Sammendraget kan ikke inneholde mer enn 8000 tegn";
        }
        if (!formData.nasjonal && formData.fylker.length === 0) {
            error.omrade =
                "Husk å velge hvilket område saken gjelder. Saken må enten være landsdekkende eller tilknyttet minst ett fylke";
        }

        setErrors(error);
        return error;
    }

    function validateUrl(lenke: string) {
        let url;

        try {
            url = new URL(lenke);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    function sortSakstype(types: string[]) {
        return types.sort((s1, s2) => s1.localeCompare(s2));
    }

    // updates list of kommuner in dropdown to selected fylkes kommuner
    function filterAndSetKommuner(allKommunerInSelectedFylker: Kommune[], selectedKommuneIds: number[] | undefined) {
        const filteredKommuner = selectedKommuneIds?.filter(
            (nr) =>
                allKommunerInSelectedFylker.find(
                    (k) => k.nummer === nr
                ) !== undefined
        );
        setFormData({
            ...formData,
            kommuner: filteredKommuner,
        });
    }

    // to set value in input for fylke
    function getFylkeObjectById(listOfFylkeIdsFromSak: number[]) {
        let listOfFylkeObjects: Fylke[] = [];

        listOfFylkeIdsFromSak.forEach((id) => {
            // get fylke by id
            let fylke = nameMapping.allFylkerMedKommuner().find(
                (fylke) => fylke.nummer === id
            );

            if (fylke !== undefined) {
                listOfFylkeObjects.push(fylke);
            }
        });
        return listOfFylkeObjects;
    }

    // to set value in input for kommune
    function getKommuneObjectById(listOfKommuneIdsFromSak: number[]) {
        let listOfKommuneObjects: Kommune[] = [];

        listOfKommuneIdsFromSak.forEach((id) => {
            // finn hvilket fylke kommunen tilhører
            nameMapping.allFylkerMedKommuner().forEach((fylke) => {
                let kommune = fylke.kommuner.find(
                    (kommune) => kommune.nummer === id
                );

                if (
                    /* selectedFylker.includes(fylke.nummer) &&*/
                    kommune !== undefined
                ) {
                    listOfKommuneObjects.push(kommune);
                }
            });
        });
        return listOfKommuneObjects;
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        // console.log("handleChange...")
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setIsFormDirty(true);
    }

    function handleToggle(e: React.ChangeEvent<HTMLInputElement>) {
        // console.log("handleToggle...")
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        setIsFormDirty(true);
    }

    function handleToggleFollowUp(e: React.ChangeEvent<HTMLInputElement>) {
        // console.log("handleToggleFollowUp...")
        setFormData({
            ...formData,
            status: sakStatus.horing,
            [e.target.name]: e.target.checked,
        });
        setIsFormDirty(true);
    }

    function handleSaktype(value: string[]) {
        // console.log("handleSaktype(", value, ")...")
        setFormData({ ...formData, sakstype: value.join(";;") });
        setIsFormDirty(true);
    }

    function handleSubmitLocal(e: FormEvent<HTMLFormElement>, formData: Sak, konfliktConfirmed?: boolean) {
        props.handleSubmit(e, formData, konfliktConfirmed);
        setIsFormDirty(false);
    }

    function handleConflictAbort() {
        if (props.onKonfliktAbort) {
            props.onKonfliktAbort();
            setIsFormDirty(true);
        }
    }

    function splitSaktype(saktype: string) {
        return saktype.split(";;");
    }

    function handleFylke(value: Fylke[]) {
        // console.log("handleFylke(", value, ")...")
        const oppdatertFylker = value.map((fylke) => fylke.nummer);
        const orgFylker = props.sak.fylker !== undefined ? props.sak.fylker : [];

        const fylkerEndret = !isEqualArrays(orgFylker, oppdatertFylker);
        // console.log(orgFylker, " vs ", oppdatertFylker, " endret:", fylkerEndret);
        if (fylkerEndret) {
            setIsFormDirty(true);
        }

        setFormData({
            ...formData,
            fylker: oppdatertFylker,
        });
    }

    function handleKommuner(value: Kommune[]) {
        // console.log("handleKommuner(", value, ")...")
        const oppdatertKommuner = value.map((kommune) => kommune.nummer);
        const orgKommuner = props.sak.kommuner !== undefined ? props.sak.kommuner : [];

        const kommunerEndret = !isEqualArrays(orgKommuner, oppdatertKommuner);
        // console.log(orgKommuner, " vs ", oppdatertKommuner, " endret:", kommunerEndret);
        if (kommunerEndret) {
            setIsFormDirty(true);
        }
        setFormData({
            ...formData,
            kommuner: oppdatertKommuner,
        });
    }

    function handleSammendrag(value: string, delta: DeltaStatic, source: Sources, editor: UnprivilegedEditor) {
        // console.log("handleSammendrag(", value, ")...")
        setFormData({
            ...formData,
            sammendrag: value,
        });
        setIsFormDirty(true);
    }

    function onBeforeUnload(e: BeforeUnloadEvent) {
        // console.log("onBeforeUnload...")
        //Unsaved form data guard 2:2
        // Trigges ved reload og lukking av fane.
        // Back-navigering håndteres av Prompt-komponenten.

        if (isFormDirty) {
            e.preventDefault();
            e.returnValue = 'Sikker på at du vil forlate siden?'; //selve teksten kommer ikke ut til bruker....
        }
    }

    if (nameMapping.isLoading) {
        return <CircularProgress />;
    }

    return (
        <div
            className={ classes.formContainer }
        >
            <Prompt
                //Unsaved form data guard 1:2
                // Håndterer back-navigering.
                // Reload og lukking av fane håndteres av "beforeunload" listener.
                when={isFormDirty}
                message={(props.isEditSak ? "Endret" : "Ny") + " sak er ikke lagret. Gå videre uten å lagre?"}
            />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    setSubmitCount(submitCount + 1);
                    const errormessages = validateForm();
                    if (Object.keys(errormessages).length === 0) {
                        handleSubmitLocal(e, formData, props.konflikt?.type);
                    }
                }}
                className="AdminForm_Form flex-column"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            classes={{ root: classes.inputField }}
                            multiline
                            name="tittel"
                            label="Tittel*"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors.tittel}
                            helperText={errors.tittel ? errors.tittel : ""}
                            margin="normal"
                            size="small"
                            variant="outlined"
                            value={formData.tittel}
                            autoFocus
                            inputRef={tittelInputRef}
                        />
                        <Grid container spacing={1}>
                            <Grid item xs={6}
                                  // style={{backgroundColor: "lightblue"}}
                            >
                                <TextField
                                    classes={{ root: classes.inputField }}
                                    name="frist"
                                    label="Svarfrist"
                                    type="date"
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    size="small"
                                    variant="outlined"
                                    value={formData.frist || ""}
                                />
                            </Grid>
                            <Grid item xs={6}
                                  // style={{backgroundColor: "lightpink"}}
                            >
                                <TextField
                                    classes={{ root: classes.inputField }}
                                    name="areal"
                                    label="Berørt areal"
                                    type="number"
                                    placeholder="100"
                                    error={!!errors.areal}
                                    helperText={
                                        errors.areal ? errors.areal : ""
                                    }
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                daa
                                            </InputAdornment>
                                        ),
                                    }}
                                    margin="normal"
                                    size="small"
                                    variant="outlined"
                                    value={formData.areal || ""}
                                />
                            </Grid>
                        </Grid>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.nasjonal}
                                    onChange={handleToggle}
                                    name="nasjonal"
                                    color="primary"
                                />
                            }
                            label="Landsdekkende"
                        />
                        <Autocomplete
                            classes={{ root: classes.inputField }}
                            multiple
                            id="checkboxes-dropdown-fylker"
                            options={nameMapping.allFylkerMedKommuner()}
                            value={getFylkeObjectById(formData.fylker)}
                            disabled={formData.nasjonal}
                            getOptionSelected={(option, value) =>
                                option.nummer === value.nummer
                            }
                            filterSelectedOptions={true}
                            getOptionLabel={(fylke) => fylke.navn}
                            onChange={(e, v) => handleFylke(v)}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.navn}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Fylker"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!errors.omrade}
                                    helperText={
                                        errors.omrade ? errors.omrade : ""
                                    }
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            classes={{ root: classes.inputField }}
                            multiple
                            id="checkboxes-dropdown-kommuner"
                            options={nameMapping.getKommunerForFylkeIds(formData.fylker)}
                            value={
                                formData.kommuner
                                    ? getKommuneObjectById(formData.kommuner)
                                    : []
                            }
                            disabled={
                                formData.fylker === undefined ||
                                formData.fylker.length === 0 ||
                                formData.nasjonal
                            }
                            getOptionSelected={(option, value) =>
                                option.nummer === value.nummer
                            }
                            filterSelectedOptions={true}
                            getOptionLabel={(kommune) => kommune.navn}
                            onChange={(e, v) => handleKommuner(v)}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.navn}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    name="kommuner"
                                    label="Kommuner"
                                    margin="normal"
                                />
                            )}
                        />
                        <Autocomplete
                            classes={{ root: classes.inputField }}
                            multiple
                            options={sortSakstype(
                                sakTypes.map((option) => option)
                            )}
                            freeSolo
                            value={
                                formData.sakstype
                                    ? splitSaktype(formData.sakstype)
                                    : []
                            }
                            onChange={(e, v) => handleSaktype(v)}
                            renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip
                                        variant="outlined"
                                        label={option}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    multiline
                                    name="sakstype"
                                    label="Sakstype"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    size="small"
                                    variant="outlined"
                                />
                            )}
                        />

                        <TextField
                            multiline
                            classes={{ root: classes.inputField }}
                            name="saksnummer"
                            label="Eksternt saksnummer"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            size="small"
                            variant="outlined"
                            value={formData.saksnummer || ""}
                        />

                        <TextField
                            multiline
                            classes={{ root: classes.inputField }}
                            name="myndighet"
                            label="Avsender"
                            type="text"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            size="small"
                            variant="outlined"
                            value={formData.myndighet || ""}
                        />

                        <TextField
                            classes={{ root: classes.inputField }}
                            multiline
                            name="lenke"
                            label="Lenke til kildeinformasjon"
                            placeholder="https://...."
                            onChange={handleChange}
                            error={!!errors.lenke}
                            helperText={errors.lenke ? errors.lenke : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            size="small"
                            variant="outlined"
                            value={formData.lenke || ""}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={styles.contentContainer}>
                            <FormControl component="fieldset">
                                <FormLabel
                                    classes={{ root: classes.labelStatus }}
                                >
                                    Status *
                                    <Tooltip
                                        placement="right"
                                        title="Knappen «Følges opp» indikerer om vedtak blir fulgt opp av koordinator. Knapp mot venstre angir at saken ikke følges opp. Knapp mot høyre indikerer at saken følges opp av koordinator, altså saken endres til status vedtatt når vedtak er klart."
                                    >
                                        <HelpOutline
                                            classes={{ root: classes.icon }}
                                        />
                                    </Tooltip>
                                </FormLabel>

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData.foelgesOpp}
                                            onChange={handleToggleFollowUp}
                                            value={formData.foelgesOpp}
                                            name="foelgesOpp"
                                            color="primary"
                                        />
                                    }
                                    label={"Følges opp?"}
                                />

                                <RadioGroup
                                    aria-label="Status"
                                    name="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                >
                                    {formData.foelgesOpp ? (
                                        <div>
                                            <FormControlLabel
                                                value={sakStatus.horing}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={sakStatus.horing}
                                            />
                                            <FormControlLabel
                                                value={sakStatus.behandles}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={sakStatus.behandles}
                                            />
                                            <FormControlLabel
                                                value={sakStatus.vedtatt}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={sakStatus.vedtatt}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <FormControlLabel
                                                value={sakStatus.horing}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={sakStatus.horing}
                                            />

                                            <FormControlLabel
                                                value={sakStatus.ingen}
                                                control={
                                                    <Radio color="primary" />
                                                }
                                                label={sakStatus.ingen}
                                            />
                                        </div>
                                    )}
                                    <FormHelperText
                                        className={styles.helperText}
                                    >
                                        <ErrorOutline />
                                        Saker som følges opp og har status «På
                                        høring» vil automatisk endres til
                                        «Behandles» etter svarfrist. Saker som
                                        ikke følges opp vil endres til "Ingen
                                        status" etter svarfrist.
                                    </FormHelperText>
                                </RadioGroup>
                            </FormControl>
                            {/*<TextField
                                classes={{
                                    root: classes.sammendrag,
                                }}
                                required
                                multiline
                                name="sammendrag"
                                label="Sammendrag"
                                placeholder="Hva, hvem, hvor, og hvorfor?"
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                size="small"
                                variant="outlined"
                                minRows={16}
                                maxRows={16}
                                id="outlined-multiline-static"
                                value={formData.sammendrag}
                            />*/}

                            <div className={styles.summary}>
                                <label
                                    className={
                                        !!errors.sammendrag
                                            ? styles.summaryLabelError
                                            : styles.label
                                    }
                                >
                                    Sammendrag *
                                    <Tooltip
                                        placement="right"
                                        title="Sammendraget skal vise leser hvorfor denne saken er aktuell. Nyttig informasjon i sammendraget kan være hensikten med planen, planområdets arealformål i gjeldende kommuneplan, mer detaljert lokalitet, natur- og friluftslivsverdier i området, eventuelle konsekvenser av planarbeidet og eventuelle folkemøter o.l."
                                    >
                                        <HelpOutline />
                                    </Tooltip>
                                </label>

                                <div
                                    className={
                                        !!errors.sammendrag
                                            ? styles.summaryError
                                            : ""
                                    }
                                >
                                    <TextEditor
                                        sammendrag={formData.sammendrag}
                                        handleChange={handleSammendrag}
                                    />
                                </div>
                                <p className={styles.errorMessage}>
                                    {!!errors.sammendrag
                                        ? errors.sammendrag
                                        : ""}
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Button
                    classes={{ root: classes.buttonSubmit }}
                    type="submit"
                    variant="contained"
                    disabled={formData === props.sak || formData === emptySak || !isFormDirty}
                >
                    {props.isEditSak ? "Oppdater sak" : "Opprett sak"}
                </Button>

                {props.konflikt !== undefined && (
                    <ConfirmSaveSakDialog
                        konflikt={props.konflikt}
                        onKonfliktAbort={handleConflictAbort}
                        formData={formData}
                    />
                )}
            </form>
        </div>
    );
};

export default SakForm;
