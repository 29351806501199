export const ROOT_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8080";

// API
export const API_BASE_PATH = ROOT_URL + "/api/v1";
export const API_SAKER_PATH = API_BASE_PATH + "/sak/";
export const API_VARSEL_PATH = API_BASE_PATH + "/varsel/";
export const API_VARSEL_ABONNEMENT_AKTIVERT_PATH = API_VARSEL_PATH + "abonnement/aktivert/";
export const API_OMRAADE_PATH = API_BASE_PATH + "/omraade/alle/";
export const API_STATISTIKK_PATH = API_BASE_PATH + "/statistikk/";

// ROUTING

export const BASE_PATH = "/";
export const SAK_PATH = "/sak/:id";
export const VARSEL_STOP_PATH = "/varsel/stopp/:id";
export const VARSEL_STOP_PATH_WITH_ID = (id: string) => "/varsel/stopp/" + id;
export const ADMIN_PATH = "/koordinator";
export const ADMIN_NEW_SAK_PATH = "/opprett-sak";
export const ADMIN_EDIT_SAK_PATH = "/rediger-sak/:id";
export const ADMIN_EDIT_SAK_PATH_WITH_ID = (id: string) => "/rediger-sak/" + id;
export const ERROR_PATH = "/noe-gikk-galt";
export const PAGE_NOT_FOUND_PATH = "/siden-finnes-ikke";
export const SAK_PATH_WITH_ID = (id: string) => "/sak/" + id;

// External static links
export const FNF_NETT_URL = "https://fnf-nett.no/";
export const FNF_SAKSPROSESS_URL = "https://fnf-nett.no/saksprosessen/";
export const FNF_PAAVIRKE_URL = "https://fnf-nett.no/tips-og-rad/";
export const FNF_VARSEL_GDPR_URL = "https://fnf-nett.no/behandling-av-data-for-varsler-fra-horingsradaren/";
