import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { CircularProgress } from "@material-ui/core";
import PublicMainPage from "../../PublicMainPage";

const KoordinatorMainPage = () => {
    return (
        <div>
            <PublicMainPage />
        </div>
    );
};

export default withAuthenticationRequired(KoordinatorMainPage, {
    onRedirecting: () => <CircularProgress />,
});
