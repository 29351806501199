import React from 'react';
import {Svarfrist, SvarfristIkkeUtloept, SvarfristUtloept} from "../../../constants/Svarfrist";
import SakFilter from "../SakFilter";

type Props = {
    filter: SakFilter;
    handleUtloptToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleIkkeUtloptToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function SvarfristFilter(props: Props) {

    function createCheckbox(svarfrist: Svarfrist, checked: boolean, toggleHandler: (e: React.ChangeEvent<HTMLInputElement>) => void) {
        return <div>
            <label htmlFor={svarfrist.paramValue} style={{cursor: "pointer"}}>
                <input
                    id={svarfrist.paramValue}
                    type="checkbox"
                    checked={checked}
                    onChange={toggleHandler}
                 />
                {svarfrist.labelFilter}
            </label>
        </div>;
    }

    return (
        <div>
            {createCheckbox(SvarfristIkkeUtloept, props.filter.getSvarfrist().includes(SvarfristIkkeUtloept.paramValue), props.handleIkkeUtloptToggle)}
            {createCheckbox(SvarfristUtloept, props.filter.getSvarfrist().includes(SvarfristUtloept.paramValue), props.handleUtloptToggle)}
        </div>
    );
}

export default SvarfristFilter;