import { sakStatus } from "./enum";

export const emptySak = {
    id: "",
    tittel: "",
    status: sakStatus.horing,
    foelgesOpp: false,
    fylker: [],
    kommuner: [],
    sammendrag: "",
    nasjonal: false,
};
