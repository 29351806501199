import React, {useEffect, useState} from "react";
import {
    ADMIN_EDIT_SAK_PATH_WITH_ID,
    API_SAKER_PATH,
    SAK_PATH_WITH_ID
} from "../../../constants/urls";
import {emptySak} from "../../../constants/initializedTypes";
import styles from "./createSak.module.css";
import SakForm from "../sakForm/SakForm";
import {UseKoordinatorContext} from "../../../context/KoordinatorContext";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {CircularProgress} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import SaveSakDialog from "../saveSakDialog/SaveSakDialog";
import ErrorPage from "../../common/errorPage/ErrorPage";
import {CONFLICT_TYPE} from "../../../constants/enum";

type Props = Sak;

const defaultProps: Props = emptySak;

const CreateSak: React.FunctionComponent<Props> = (props) => {
    const token = UseKoordinatorContext().token;
    const [success, setSuccess] = useState(false);
    const [konflikt, setKonflikt] = useState<Konflikt | undefined>();
    const [open, setOpen] = useState(false);
    const [idCreatedSak, setIdCreatedSak] = useState<string>("");
    const [error, setError] = useState(false);

    const [multiSakPrepareNewNr, setMultiSakPrepareNewNr] = useState(0)
    const [multisakCreatedSakIds, ] = useState<string[]>([])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sak, ] = useState<Sak>(props);

    const history = useHistory();

    useEffect(() => {
        setOpen(success)
    }, [success]);

    function handleToSak() {
        history.push(SAK_PATH_WITH_ID(idCreatedSak));
    }

    function onEditSak() {
        history.replace(ADMIN_EDIT_SAK_PATH_WITH_ID(idCreatedSak));
    }

    function onMultiSakPrepareNew() {
        // console.log("onMultiSakPrepareNew...")
        setSuccess(false)
        setMultiSakPrepareNewNr(multisakCreatedSakIds.length + 1)
    }

    function handleKonfliktAbort() {
        // clear konflikt
        setKonflikt(undefined)
    }

    function addMultisakCreatedId(id: string) {
        // console.log("addMultisakCreatedId(", id, ")...")
        multisakCreatedSakIds.push(id)
    }

    function handleSubmit(e: any, formData: Sak, konfliktConfirmed?: boolean) {
        e.preventDefault();

        const idempotencyKey = crypto.randomUUID();

        if (konflikt) {
            // clear konflikt before POST
            setKonflikt(undefined)
        }

        let url = API_SAKER_PATH;
        if (konfliktConfirmed) {
            url += "?overrideConflict=true";
        }
        fetch(url, {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Idempotency-Key": idempotencyKey,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else if (res.status === 409) {
                    return res.json();
                }
                throw new Error(res.statusText);
            })
            .then((res) => {
                if (res.conflictType !== undefined) {
                    if (Object.values(CONFLICT_TYPE).includes(res.conflictType)) {
                        const konflikt: Konflikt = {
                            type: res.conflictType,
                            ids: res.conflictIds,
                        }
                        setKonflikt(konflikt);
                    } else {
                        throw new Error("Unknown conflict");
                    }
                } else {
                    addMultisakCreatedId(res.id)
                    setSuccess(true);
                    setIdCreatedSak(res.id);
                    e.target.reset();
                }
            })
            .catch((error) => {
                setError(true);
                console.log(error);
            });
    }

    if (error) {
        return <ErrorPage />;
    }

    return (
        <div className={styles.createSakMain}>
            <h2>Opprett sak</h2>
            <SakForm
                sak={sak}
                handleSubmit={handleSubmit}
                isEditSak={false}
                konflikt={konflikt}
                onKonfliktAbort={handleKonfliktAbort}
                multiSakPrepareNewNr={multiSakPrepareNewNr}
            />

            {success && (
                <SaveSakDialog
                    success={open}
                    handleToSak={handleToSak}
                    onClose={onEditSak}
                    onMultiSakPrepareNew={onMultiSakPrepareNew}
                    isEditSak={false}
                />
            )}
        </div>
    );
};

CreateSak.defaultProps = defaultProps;

export default withAuthenticationRequired(CreateSak, {
    onRedirecting: () => <CircularProgress />,
});
