import React from "react";
import styles from "./searchChips.module.css";
import Chip from "@material-ui/core/Chip";
import {useNameMapping} from "../../../context/NameMappingContext";
import {AlleSaksnivaa, Saksnivaa} from "../../../constants/Saksnivaa";
import {AlleSvarfrist, Svarfrist} from "../../../constants/Svarfrist";
import SakFilter from "../SakFilter";
import {useMediaQuery, useTheme} from "@material-ui/core";

type Props = {
    filter: SakFilter;
    onResetFilterClick: () => void;
    handleDeleteKommune: (nummer: number) => void;
    handleDeleteFylke: (nummer: number) => void;
    handleChangeSaksnivaa: (saksnivaa: Saksnivaa, b: boolean) => void;
    handleChangeSvarfrist: (svarfrist: Svarfrist, b: boolean) => void;
    handleDeleteFritekst: () => void;
};

function SearchChips(props: Props) {

    const nameMapping = useNameMapping();
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div className={isMobileScreen ? styles.chipsRootMobile : styles.chipsRoot}>
            {!props.filter.isEmpty() && isMobileScreen && (
                // Trix: spacer to overlap Filter button...
                <div className={styles.chipsRootMobileSpacer}></div>
            )}
            {/*{(props.filter.isEmpty()) && (isMobileScreen) && (*/}
            {/*    <div className={styles.chipsRootMobileNoFilter}>*/}
            {/*        <span style={{*/}
            {/*            color: theme.palette.grey["700"],*/}
            {/*        }}>ingen filtre valgt...</span>*/}
            {/*    </div>*/}
            {/*)}*/}
            {(!props.filter.isEmpty()) && (!isMobileScreen) ?
                <Chip label={SakFilter.getNullstillTextMedium(props.filter.getCount())} variant="default" color="primary" clickable={true}
                      onClick={props.onResetFilterClick} className={styles.filterChip}/>
                :
                ''
            }
            {(props.filter.getFritekst() != null) && (
                <Chip key={42} label={"\"" + props.filter.getFritekst() + "\""}  variant="outlined" clickable={true}
                      onDelete={() => props.handleDeleteFritekst()} className={styles.filterChip}
                />
            )}
            {AlleSvarfrist.filter((frist) => { return props.filter.getSvarfrist().includes(frist.paramValue) }).map((frist) => {
                return (
                    <Chip key={frist.paramValue} label={frist.labelChip} variant="default" clickable={true}
                          onDelete={() => props.handleChangeSvarfrist(frist, false)} className={styles.filterChip}/>
                );
            })
            }
            {AlleSaksnivaa.filter((nivaa) => { return props.filter.getSaksnivaa().includes(nivaa.paramValue) }).map((nivaa) => {
                return (
                    <Chip key={nivaa.paramValue} label={nivaa.labelChip} variant="default" clickable={true}
                          onDelete={() => props.handleChangeSaksnivaa(nivaa, false)} className={styles.filterChip}/>
                );
            })
            }
            {nameMapping.getFylker(props.filter.getFylker()).sort((f1, f2) => {return f1.navn.localeCompare(f2.navn, "no")}).map((fylke) => {
                return (
                    <Chip key={fylke.nummer} label={fylke.navn}  variant="default" clickable={true}
                          onDelete={() => props.handleDeleteFylke(fylke.nummer)} className={styles.filterChip}
                          // icon={<RoomIcon />}
                          // avatar={<Avatar>F</Avatar>}
                    />
                );
            })
            }
            {nameMapping.getKommuner(props.filter.getKommuner()).sort((f1, f2) => {return f1.navn.localeCompare(f2.navn, "no")}).map((kommune) => {
                return (
                    <Chip key={kommune.nummer} label={kommune.navn}  variant="default" clickable={true}
                          onDelete={() => props.handleDeleteKommune(kommune.nummer)} className={styles.filterChip}
                          // icon={<RoomIcon />}
                          // avatar={<Avatar>K</Avatar>}
                    />
                );
            })
            }
        </div>
    );
}

export default SearchChips;
