import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {API_VARSEL_PATH, BASE_PATH,} from "../../../constants/urls";
import {useNameMapping} from "../../../context/NameMappingContext";
import styles from "./varselStopp.module.css";
import "../../../styles/App.css";
import {CircularProgress} from "@material-ui/core";
import ErrorPage from "../errorPage/ErrorPage";

const VarselStopp = () => {

    const { id } = useParams<{ id: string }>();

    const loadingContext = useNameMapping().isLoading;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [varselFound, setVarselFound] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetch(API_VARSEL_PATH + id, {
            method: "DELETE",
            })
            .then((res) => {
                if (res.ok) {
                    setVarselFound(true);
                    return res.json();

                } else if (res.status === 404) {
                    setVarselFound(false);
                    return res.ok;
                }
                throw new Error(res.statusText);
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                setError(true);
                setIsLoading(false);
                console.log(error);
            });
    }, [id]);

    if (error) {
        return <ErrorPage />;
    }
    if (isLoading || loadingContext) {
        return <CircularProgress />;
    }

    return (
        <div style={{ height: "100%" }}>

            <div className={styles.content} hidden={!varselFound}>
                <p>
                    Ditt abonnement på varsler er stoppet og din informasjon blir slettet fra våre systemer.
                </p>
                <p>
                    <a href={BASE_PATH}>Her kan du abonnere på et nytt søk.</a>
                </p>
            </div>

            <div className={styles.content} hidden={varselFound}>
                <p>Varsel ikke funnet</p>
            </div>
        </div>
    );
};

export default VarselStopp;
