import { GridCellValue } from "@mui/x-data-grid";

function dateFormatConverter(
    date: string | undefined | null | GridCellValue,
    yearShort?: boolean
) {
    if (!date) return "";
    if (yearShort) {
        date = date.toString();

        const splitDate = date.split("-");

        const year = splitDate[0].slice(-2);
        const month = splitDate[1];
        const day = splitDate[2];

        return [day, month, year].join(".");
    } else {
        date = date.toString();

        const splitDate = date.split("-");

        const year = splitDate[0];
        const month = splitDate[1];
        const day = splitDate[2];

        return [day, month, year].join(".");
    }
}

export default dateFormatConverter;
