export type Saksnivaa = {
    paramValue: string,
    labelFilter: string,
    labelChip: string,
};

const SaksnivaaKommune: Saksnivaa = {
    paramValue: "kommune",
    labelFilter: "Kommune",
    labelChip: "Kommunale saker"
};
const SaksnivaaFylke: Saksnivaa = {
    paramValue: "fylke",
    labelFilter: "Fylke",
    labelChip: "Fylkeskommunale saker"
};
const SaksnivaaNasjonal: Saksnivaa = {
    paramValue: "nasjonal",
    labelFilter: "Landsdekkende",
    labelChip: "Landsdekkende saker"
};

const AlleSaksnivaa: Saksnivaa[] = [SaksnivaaKommune, SaksnivaaFylke, SaksnivaaNasjonal];
const AlleSaksnivåParamValues = AlleSaksnivaa.map(s => s.paramValue)

function isValidSaksnivaaParam(str: string) {
    return AlleSaksnivåParamValues.includes(str);
}

export {SaksnivaaKommune, SaksnivaaFylke, SaksnivaaNasjonal, AlleSaksnivaa, isValidSaksnivaaParam};

