import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useHistory} from "react-router-dom";
import {
    API_SAKER_PATH,
    ERROR_PATH,
} from "../../../constants/urls";
import {makeStyles, useMediaQuery, useTheme} from "@material-ui/core";
import "../../../styles/App.css";
import ButtonWithActionAndTooltip from "../buttons/ButtonWithActionAndTooltip";
import {Close, GetApp} from "@material-ui/icons";
import styles from "./export.module.css";
import ButtonWithAction from "../buttons/ButtonWithAction";
import StaticSearchChips from "../chips/StaticSearchChips";
import SakFilter from "../SakFilter";

type Props = {
    sakFilter: SakFilter;
    saker: Sak[];
};

const useStyles = makeStyles((theme) => ({
    inputField: {
        width: "100%",
        maxWidth: "500px",
    },
    buttonAction: {
        borderRadius: "40px",
        padding: "6px 16px",
        maxHeight: "40px",
        boxShadow: "none",
        marginLeft: "auto",
        marginTop: "4px",
        marginBottom: "4px",
        marginRight: "4px",
        textTransform: "none",
        "&:hover, &:focus": {
            boxShadow: "none",
        },
    },
    labelStatus: {
        fontSize: "14px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {
        marginLeft: "4px",
        fontSize: "20px",
    },
    alertSuccess: {
        backgroundColor: theme.palette.secondary.main,
    },
    alertError: {
        backgroundColor: theme.palette.error.main,
    }
}));


const Export = (props: Props) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [error, setError] = useState(false);
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const classes = useStyles(theme);

    const handleClickOpen = () => {
        setOpen(true);
        // setFormData(props);
    };

    const handleClose = () => {
        // clearFormData();
        setOpen(false);
    };

    function getAttachmentFilename(res: Response, defaultName: string) {
        let filename = res.headers
            .get("content-disposition")?.split(";")
            .find(n => n.includes('filename='))?.replace('filename=', '').trim();
        return filename ? filename : defaultName;
    }

    function downloadBlob(blob: Blob, filename: string) {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', filename);
        link.download = filename;
        link.type = blob.type

        document.body.appendChild(link);

        link.click();

        //clean-up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    async function handleSubmit() {

        // console.log("QP: ", JSON.stringify(formData));

        const path = API_SAKER_PATH + "eksport/";

        // const urlSearchParams = new URLSearchParams();

        let filename: string = "";
        // let filter = formData.filterParams.getFilter();
        let filter = props.sakFilter.getFilter();
        let eksport: Eksport = {
            saker: props.saker,
            filter: {
                fylker: filter.fylker,
                kommuner: filter.kommuner,
                saksnivaa: filter.saksnivaa,
                svarfrist: filter.svarfrist,
                fritekst: filter.fritekst
            },
            originalUrl: window.location.href
        };
        // console.log("eksport: ", eksport)

        fetch(path, {
            method: "POST",
            body: JSON.stringify(eksport),
            headers: {
                "Content-Type": "application/json",
                accept: `*/*`
            },
        })
            .then((res) => {
                if (res.ok) {
                    filename = getAttachmentFilename(res, "ukjent.xlsx");
                    return res.blob();

                } else {
                    throw new Error(res.statusText);
                }
            })
            .then((blob) => {
                downloadBlob(blob, filename);
                handleClose();
            })
            .catch((error) => {
                console.log(error);
                setError(true);
                history.push(ERROR_PATH);
            }).finally(() => {
            // setIsLoading(false);
        });
    }

    if (error) {
        console.log("Error", error);
        history.push(ERROR_PATH);
    }


    return (
        <div style={{
            // backgroundColor: "lightgrey",
            // border: "2px solid darkgrey",
        }}>
            <ButtonWithActionAndTooltip
                title={"Eksporter"}
                color={theme.palette.info.light}
                classes={{root: classes.buttonAction}}
                onClick={handleClickOpen}
                disabled={!props.sakFilter.hasSearchParamsForEksport() || !(props.saker.length > 0)}
                icon={<GetApp/>}
                enabledTooltip={"Eksporter liste over saken" + (props.saker.length > 1 ? "e" : "")}
                disabledTooltip={props.sakFilter.isEmpty() ? "Avgrens søket først" : "Ingen saker å laste ned"}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        boxShadow: "none",
                    },
                }}
                fullScreen={isMobileScreen}
            >
                <DialogContent>

                    <DialogTitle>{isMobileScreen ? "Eksport" : "Eksport"}</DialogTitle>

                    <DialogContentText id="alert-dialog-description">
                        <div>
                            <p>Last ned Excel-dokument med liste over {props.saker.length > 1 ? "alle " + props.saker.length + " treff" : "treffet"} på søket</p>
                            {/*{isMediaMobile && (*/}
                                <StaticSearchChips
                                    filter={props.sakFilter}
                                />
                            {/*)}*/}
                        </div>
                    </DialogContentText>


                </DialogContent>
                <DialogActions className={styles.actionButtons}>
                    <ButtonWithAction
                        title={"Avbryt"}
                        color={theme.palette.grey["600"]}
                        onClick={handleClose}
                        icon={<Close/>}
                    />
                    <ButtonWithAction
                        title={"Last ned"}
                        color={theme.palette.primary.main}
                        onClick={handleSubmit}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Export;
