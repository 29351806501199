import React from "react";
import styles from "./areaFilter.module.css";
import { useNameMapping } from "../../../context/NameMappingContext";
import sakFilter from "../SakFilter";

type Props = {
    filter: sakFilter;
    handleSelectedFylker: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectedKommuner: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function isFylkeSelected(fylke: Fylke, selectedFylker: number[]) {
    return selectedFylker.includes(fylke.nummer);
}

function isKommuneInFylkeSelected(fylke: Fylke, selectedKommuneNrs: number[]) {
    const kommuneNrsInFylke: number[] = fylke.kommuner.map<number>(
        (komm) => komm.nummer
    );

    const intersection = kommuneNrsInFylke.filter((knr) =>
        selectedKommuneNrs.includes(knr)
    );

    return intersection.length > 0;
}

const AreaFilter = (props: Props) => {
    const allFylkerMedKommuner = useNameMapping().allFylkerMedKommuner();

    return (
        <div>
            <ul className={styles.list}>
                {allFylkerMedKommuner.map((fylke) => (
                    <li key={fylke.nummer} className={styles.listItem}>
                        <label htmlFor={fylke.navn.concat("_", fylke.nummer.toString())}>
                            <input
                                id={fylke.navn.concat("_",fylke.nummer.toString())}
                                type="checkbox"
                                value={fylke.nummer}
                                onChange={(e) => {
                                    props.handleSelectedFylker(e);
                                }}
                                checked={props.filter.getFylker().includes(
                                    fylke.nummer
                                )}
                            />
                            {fylke.navn}
                        </label>

                        {fylke.aktiv && (isFylkeSelected(fylke, props.filter.getFylker()) ||
                            isKommuneInFylkeSelected(
                                fylke,
                                props.filter.getKommuner()
                            )) && (
                            <ul className={styles.listSecond}>
                                {fylke.kommuner.map((kommune) => (
                                    <li
                                        key={kommune.nummer}
                                        className={styles.listItem}
                                    >
                                        <label htmlFor={kommune.navn.concat("_", kommune.nummer.toString())}>
                                        <input
                                            id={kommune.navn.concat("_", kommune.nummer.toString())}
                                            type="checkbox"
                                            value={kommune.nummer}
                                            onChange={
                                                props.handleSelectedKommuner
                                            }
                                            checked={props.filter.getKommuner().includes(
                                                kommune.nummer
                                            )}/>
                                        {kommune.navn}
                                        </label>

                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AreaFilter;
