import React from "react";

function NoSakerFoundMessage() {
    return (
        <p
            style={{
                margin: "120px auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
            }}
        >
            Fant ingen saker.
        </p>
    );
}

export default NoSakerFoundMessage;
