import React from "react";
import {Saksnivaa, SaksnivaaFylke, SaksnivaaKommune, SaksnivaaNasjonal} from "../../../constants/Saksnivaa";
import SakFilter from "../SakFilter";

type Props = {
    filter: SakFilter;
    handleKommunalToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleFylkeskommunalToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleNasjonalToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SaksnivaaFilter = (props: Props) => {

    function createSaksnivaa(nivaa: Saksnivaa, checked: boolean, toggleHandler: (e: React.ChangeEvent<HTMLInputElement>) => void) {
        return (
            <div>
                <label htmlFor={nivaa.paramValue} style={{cursor: "pointer"}}>
                    <input
                        id={nivaa.paramValue}
                        type="checkbox"
                        checked={checked}
                        onChange={toggleHandler}
                    />
                    {nivaa.labelFilter}
                </label>
            </div>
        )
    }

    return (
        <div>
            {createSaksnivaa(SaksnivaaKommune, props.filter.getSaksnivaa().includes(SaksnivaaKommune.paramValue), props.handleKommunalToggle)}
            {createSaksnivaa(SaksnivaaFylke,  props.filter.getSaksnivaa().includes(SaksnivaaFylke.paramValue), props.handleFylkeskommunalToggle)}
            {createSaksnivaa(SaksnivaaNasjonal,  props.filter.getSaksnivaa().includes(SaksnivaaNasjonal.paramValue), props.handleNasjonalToggle)}
        </div>
    );
};

export default SaksnivaaFilter;
