import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const UserInformation = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return (
        <div>
            {isAuthenticated && (
                <div>
                    <p>Innlogget med epost: {user?.email}</p>
                </div>
            )}
        </div>
    );
};

export default UserInformation;
