import React from "react";
import {createStyles, makeStyles, TablePagination, Theme} from "@material-ui/core";
import {LabelDisplayedRowsArgs} from "@material-ui/core/TablePagination/TablePagination";

type Props = {
    saker: Sak[];
    sakerPrPage: number;
    page: number;

    handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    handleChangeRowsPerPage: (event: any) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // backgroundColor: "pink",
            display: "flex",
            // width: "100%",
        },
        insideRoot: {
            // backgroundColor: 'darkgray',
            paddingLeft: "2px", //was 24

            [theme.breakpoints.up('sm')]: {
                // backgroundColor: 'green',
                paddingLeft: "24px", //was 24
            },
        },
        spacer: {
            // backgroundColor: "blue",
            // default 0 x 0
        },
        captions: {
            // backgroundColor: "lightblue",
            // rendered as p-tags
        },
        selectRoot: {
            // backgroundColor: "red",
        },
        dropdownInputActive: {
            // backgroundColor: "orange",
            marginRight: "8px", //was 32
            marginLeft: "4px", // was8
            [theme.breakpoints.up('sm')]: {
                marginRight: "32px", //was 32
                marginLeft: "8px", // was 8
            }
        },
        dropdownInputNotActive: {
            // backgroundColor: "yellow",
        },
        dropdownIcon: {
            // backgroundColor: "lightgreen",
        },
        dropDownMenuItemNotSelected: {
            // backgroundColor: "blue",
        },
        pagers: {
            // backgroundColor: "lightsalmon",
            marginLeft: "4px", //was 20px
            [theme.breakpoints.up('sm')]: {
                marginLeft: "20px", //was 20px
            }
        },
    })
);

function SakTablePagination(props: Props) {

    const styles = useStyles();

    return (
        <TablePagination

            classes={{
                root: styles.root,
                toolbar: styles.insideRoot,
                spacer: styles.spacer,
                caption: styles.captions,
                selectRoot: styles.selectRoot,
                select: styles.dropdownInputNotActive,
                selectIcon: styles.dropdownIcon,
                input: styles.dropdownInputActive,
                menuItem: styles.dropDownMenuItemNotSelected,
                actions: styles.pagers,
            }}
            rowsPerPageOptions={[10, 50, 100, { label: 'Alle', value: SAKER_PR_PAGE_ALL_VALUE }]}
            component="div" //er default <td>
            count={props.saker.length}
            rowsPerPage={props.sakerPrPage}
            page={props.page}
            labelRowsPerPage={"Vis"}
            // labelRowsPerPage={"Saker pr side"}
            // labelRowsPerPage={props.saker.length + " saker, viser"}
            labelDisplayedRows={
                (pgInfo: LabelDisplayedRowsArgs) => {
                    if (pgInfo.count === 0) {
                        return "" + pgInfo.count + " saker"
                    } else if (pgInfo.count === 1) {
                        return "" + pgInfo.count + " sak"
                    } else if (pgInfo.to === SAKER_PR_PAGE_ALL_VALUE) {
                        return "" + pgInfo.count + " saker"
                    } else {
                        return "" + pgInfo.from + "-" + pgInfo.to + " av " + pgInfo.count + " saker"
                        // return "side " + (pgInfo.page+1) + ":" + Math.ceil(pgInfo.count / props.sakerPrPage) + " av " + pgInfo.count + " saker"
                        // return "side " + (pgInfo.page+1) + " av " + Math.ceil(pgInfo.count / props.sakerPrPage)
                        // return "" + (pgInfo.page+1) + " av " + Math.ceil(pgInfo.count / props.sakerPrPage)
                        // return "" + (pgInfo.page+1) + " : " + Math.ceil(pgInfo.count / props.sakerPrPage)
                        // return "saker pr side " + (pgInfo.page+1) + " : " + Math.ceil(pgInfo.count / props.sakerPrPage)
                        // return "pr side " + (pgInfo.page+1) + " : " + Math.ceil(pgInfo.count / props.sakerPrPage)
                    }
                }
            }
            nextIconButtonText={'Neste side'}
            backIconButtonText={'Forrige side'}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
    );
}

export default SakTablePagination;
export const SAKER_PR_PAGE_ALL_VALUE = -1;
