import React from "react";
import { Button } from "@material-ui/core";
import {ClassNameMap} from "@material-ui/styles/withStyles/withStyles";

type Props = {
    title: string;
    color?: string;
    icon?: any;
    disabled?: boolean;
    classes?: Partial<ClassNameMap>;
    isSubmit?: boolean;
    onClick: () => void;
};

function ButtonWithAction(props: Props) {
    return (
        <Button
            endIcon={props.icon}
            disabled={props.disabled}
            onClick={props.onClick}
            style={{ backgroundColor: props.color }}
            classes={props.classes}
            type={props.isSubmit ? "submit" : undefined}
        >
            {props.title}
        </Button>
    );
}

export default ButtonWithAction;