import React from "react";
import {Button, Zoom} from "@material-ui/core";
import {ClassNameMap} from "@material-ui/styles/withStyles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

type Props = {
    title: string;
    color: string;
    icon?: any;
    disabled?: boolean;
    classes?: Partial<ClassNameMap>;
    enabledTooltip?: string;
    disabledTooltip?: string;
    onClick: () => void;
};

function ButtonWithActionAndTooltip(props: Props) {

    return (
        <Tooltip
            title={props.disabled ? (props.disabledTooltip ? props.disabledTooltip : '') : (props.enabledTooltip ? props.enabledTooltip : '')}
            TransitionComponent={Zoom}
        >
            <div>
                {/* wrapping Button in div to enable tooltip for disabled button */}

                <Button
                    endIcon={props.icon}
                    onClick={props.onClick}
                    style={{backgroundColor: props.color}}
                    disabled={props.disabled}
                    classes={props.classes}
                >
                    {props.title}
                </Button>
            </div>
        </Tooltip>
    );
}

export default ButtonWithActionAndTooltip;
