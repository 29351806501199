import React from "react";

function FilterIcon() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 12H21"
                stroke="#141414"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3 6H21"
                stroke="#141414"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3 18H21"
                stroke="#141414"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="7" cy="6" r="3" fill="#141414" />
            <circle cx="17" cy="12" r="3" fill="#141414" />
            <circle cx="7" cy="18" r="3" fill="#141414" />
        </svg>
    );
}

export default FilterIcon;
