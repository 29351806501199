import {useState} from "react";
import styles from "./alertBox.module.css";

type Props = {
    msg?: string;
};

const AlertBox = (props: Props) => {

    const [visible, setVisible] = useState(true);

    const closeAlert = () => {
        setVisible(false);
    };

    if (props.msg && props.msg.trim().length > 0 && visible) {
        return (
            <div className={styles.container} >
                <span>{props.msg}</span>
                <a href="#" onClick={closeAlert} className={styles.link}/>
            </div>
        );
    } else {
        return null;
    }
};

export default AlertBox;

