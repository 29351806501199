export type Svarfrist = {
    paramValue: string,
    labelFilter: string,
    labelChip: string,
};

const SvarfristIkkeUtloept: Svarfrist = {
    paramValue: "ikkeutloept",
    labelFilter: "Ikke utløpt",
    labelChip: "Svarfrist ikke utløpt"
};
const SvarfristUtloept: Svarfrist = {
    paramValue: "utloept",
    labelFilter: "Utløpt",
    labelChip: "Svarfrist utløpt"
};

const AlleSvarfrist: Svarfrist[] = [SvarfristIkkeUtloept, SvarfristUtloept];
const AlleSvarfristParamValues = AlleSvarfrist.map(s => s.paramValue)

function isValidSvarfristParam(str: string) {
    return AlleSvarfristParamValues.includes(str);
}


export {SvarfristIkkeUtloept, SvarfristUtloept, AlleSvarfrist, isValidSvarfristParam};

