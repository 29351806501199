import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Initial state of the context
type InitialState = {
    token: string;
    isLoading: boolean;
};

type Props = {
    children: ReactNode;
};

// Creates the context
const KoordinatorContext = createContext<InitialState | null>(null);

// provider gives its children access to the values of the context
function KoordinatorContextProvider(props: Props) {
    const { children } = props;
    const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: `https://hoeringsradaren.no`,
                    scope: "create:sak update:sak delete:sak",
                });

                setToken(accessToken);
            } catch (e) {
                if (e instanceof Error) {
                    console.log(e.message);
                } else {
                    console.log("Unknown error: ", e);
                }
            }
        };
        if (isAuthenticated) {
            getAccessToken();
        }
        setIsLoading(false);
    }, [isAuthenticated, getAccessTokenSilently]);

    return (
        <KoordinatorContext.Provider value={{ token, isLoading }}>
            {children}
        </KoordinatorContext.Provider>
    );
}

export default KoordinatorContextProvider;

// this function can be used in the child components of the context provider to get access to the context
export const UseKoordinatorContext = () => {
    const context = useContext(KoordinatorContext);
    if (!context) {
        throw new Error(
            `useKoordinatorContext must be used within the KoordinatorContextProvider`
        );
    }

    return context;
};
