import React from "react";
import {sakStatus} from "../../../constants/enum";
import {useTheme} from "@material-ui/core";
import styles from "./dot.module.css";

type Props = {
    status: sakStatus;
};

function Dot(props: Props) {
    const theme = useTheme();

    const styleByStatus = new Map([
        [sakStatus.horing,    {backgroundColor: theme.palette.primary.main}],
        [sakStatus.behandles, {backgroundColor: theme.palette.info.light}],
        [sakStatus.vedtatt,   {backgroundColor: theme.palette.error.dark}],
    ]);

    return (
        <div>
            <div
                className={styles.dot}
                style={styleByStatus.get(props.status)}
            />
        </div>
    );
}

export default Dot;
