import { GridCellValue } from "@mui/x-data-grid";

function dateTimeFormatConverter(
    dateTime: string | undefined | null | GridCellValue
) {
    if (!dateTime) return "";
    else {
        dateTime = dateTime.toString();
        //ex: 2022-09-14T13:10:54.526

        const split = dateTime.split("T");
        const date = split[0];
        const time = split[1];

        const splitDate = date.split("-");
        const year = splitDate[0];
        const month = splitDate[1];
        const day = splitDate[2];

        const splitTime = time.split(":");
        const hour = splitTime[0];
        const minute = splitTime[1];

        return [day, month, year].join(".") + " " + [hour, minute].join(":");
    }
}

export default dateTimeFormatConverter;
