import {QUERY_PARAMS} from "../constants/enum";
import {SvarfristIkkeUtloept} from "../constants/Svarfrist";
import {SaksnivaaFylke, SaksnivaaKommune} from "../constants/Saksnivaa";

export function isEqualArrays(a: number[], b: number[]) {

    if (a.length !== b.length)
        return false;
    else {
        const aSorted = a.sort();
        const bSorted = b.sort();
        for (let i = 0; i < aSorted.length; i++)
            if (aSorted[i] !== bSorted[i])
                return false;
        return true;
    }
}

export function todayISOString() {
    return new Date().toISOString().split("T")[0];
}

export function yesterdayISOString() {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    return date.toISOString().split("T")[0];
}

export function getDefaultQuery() {
    return createUrl({
        [QUERY_PARAMS.SVARFRIST]: [SvarfristIkkeUtloept.paramValue],
        [QUERY_PARAMS.SAKSNIVAA]: [SaksnivaaFylke.paramValue, SaksnivaaKommune.paramValue]
    });
}

export function createUrl(params: Record<string, string[]>) {
    const query = new URLSearchParams();

    Object.entries(params).forEach(([key, values]) => {
        values.forEach(value => query.append(key, value));
    });

    return `?${query.toString()}`;
}
