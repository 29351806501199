import * as React from "react";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import dateFormatConverter from "../../../utils/dateFormatConverter";
import {useNameMapping} from "../../../context/NameMappingContext";
import {
    createStyles,
    Hidden,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Theme,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import NoSakerFoundMessage from "./NoSakerFoundMessage";
import styles from "./sakerDataGrid.module.css";
import Dot from "./Dot";
import {sakStatus} from "../../../constants/enum";
import SakTablePagination, {SAKER_PR_PAGE_ALL_VALUE} from "../pagination/SakTablePagination";
import Export from "../export/Export";
import VarselCreateForm from "../varselCreateForm/VarselCreateForm";
import {useAuth0} from "@auth0/auth0-react";
import SakFilter from "../SakFilter";
import dateTimeFormatConverter from "../../../utils/dateTimeFormatConverter";
import {SORT_COLUMN, useSortingContext} from "../../../context/SortingContext";
import ErrorPage from "../errorPage/ErrorPage";
import {todayISOString} from "../../../utils/utils";

type Props = {
    saker: Sak[];
    sakerPrPage: number;
    setSakerPrPage: (n: number) => void;
    sakFilter: SakFilter;
    isVarselAbonnementAktivert: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        columnHeader: {
            color: theme.palette.secondary.main,
        },
        columnHeaderRightAligned: {
            textAlign: "right",
        },
    })
);

const SakerDataGrid = (props: Props) => {
    const { isAuthenticated } = useAuth0();
    const [saker, setSaker] = useState<Sak[]>(props.saker);
    const [page, setPage] = useState<number>(0);
    const [pageSaker, setPageSaker] = useState<Sak[]>([]);
    const getOmraadeString = useNameMapping().getOmraadeTekst;

    const sortingCtx = useSortingContext();
    const [error, setError] = useState(false);

    const theme = useTheme();
    const isThemeBreakpointDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    // eslint-disable-next-line
    const isThemeBreakpointDownMD = useMediaQuery(theme.breakpoints.down('md'));
    // eslint-disable-next-line
    const isThemeBreakpointDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const classes = useStyles();

    useEffect(() => {
        try {
            setError(false);
            setSaker(sortingCtx.sortSaker(saker));

        } catch (e) {
            if (e instanceof Error) {
                console.log(e.message);
            } else {
                console.log("Unknown error: ", e);
            }
            setError(true);

        }
        // eslint-disable-next-line
    }, [sortingCtx]);

    useEffect(() => {
        setPageSaker(getPageSaker(saker, page, props.sakerPrPage));
    }, [saker, page, props.sakerPrPage]);


    function getPageSaker(saker: Sak[], pageIndex: number, rowsPerPage: number) {
        let startInclusive = rowsPerPage !== SAKER_PR_PAGE_ALL_VALUE ? pageIndex * rowsPerPage : 0;
        let endExclusice = rowsPerPage !== SAKER_PR_PAGE_ALL_VALUE ? startInclusive + rowsPerPage : saker.length;
        if (endExclusice >= saker.length) {
            endExclusice = saker.length;
        }
        return saker.slice(startInclusive, endExclusice);
    }

    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, page: number){
        setPage(page);
    }

    function handleChangeRowsPerPage(event: any) {
        props.setSakerPrPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    if (error) {
        return <ErrorPage />;
    }

    return (
        <div>
            <div className={styles.topContainer}
                >
                {!isAuthenticated && (
                    <div style={{
                        // backgroundColor: "lightblue",
                        alignItems: "center",
                        display: "flex",
                    }}>
                        {/*{!props.isLoadingAbonnementAktivert && (*/}
                            <VarselCreateForm
                                varselAbonnementAktivert={props.isVarselAbonnementAktivert}
                                sakFilter={props.sakFilter}
                            />
                        {/*)}*/}
                        {/*{saker.length > 0 && (*/}
                            <Export
                                sakFilter={props.sakFilter}
                                saker={saker}
                            />
                        {/*)}*/}

                    </div>
                )}
                <div style={{
                    // backgroundColor: "blue",
                    display: "flex"
                }}>
                    <SakTablePagination
                        saker={saker}
                        sakerPrPage={props.sakerPrPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>
            </div>
            <Table className={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                classes={{ active: classes.columnHeader }}
                                active={sortingCtx.isColumn(SORT_COLUMN.TITTEL)}
                                direction={sortingCtx.getOrder()}
                                onClick={() => sortingCtx.requestSort(SORT_COLUMN.TITTEL)}
                            >
                                Tittel
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                classes={{ active: classes.columnHeader }}
                                active={sortingCtx.isColumn(SORT_COLUMN.OMRADE)}
                                direction={sortingCtx.getOrder()}
                                onClick={() => sortingCtx.requestSort(SORT_COLUMN.OMRADE)}
                            >
                                Område
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.columnHeaderRightAligned}>
                            <TableSortLabel
                                active={sortingCtx.isColumn(SORT_COLUMN.FRIST)}
                                direction={sortingCtx.getOrder()}
                                onClick={() => sortingCtx.requestSort(SORT_COLUMN.FRIST)}
                            >
                                Frist
                            </TableSortLabel>
                        </TableCell>
                        <Hidden smDown>
                            <TableCell>
                                <TableSortLabel
                                    classes={{
                                        active: classes.columnHeader,
                                        root: classes.columnHeader,
                                    }}
                                    active={sortingCtx.isColumn(SORT_COLUMN.STATUS)}
                                    direction={sortingCtx.getOrder()}
                                    onClick={() =>
                                        sortingCtx.requestSort(SORT_COLUMN.STATUS)
                                    }
                                >
                                    Status
                                </TableSortLabel>
                            </TableCell>
                        </Hidden>
                        <Hidden smDown>
                            <TableCell className={classes.columnHeaderRightAligned}>
                                <TableSortLabel
                                    active={sortingCtx.isColumn(SORT_COLUMN.AREAL)}
                                    direction={sortingCtx.getOrder()}
                                    onClick={() =>
                                        sortingCtx.requestSort(SORT_COLUMN.AREAL)
                                    }
                                >
                                    Areal
                                </TableSortLabel>
                            </TableCell>
                        </Hidden>
                        <Hidden mdDown>
                            <TableCell>
                                <TableSortLabel
                                    active={sortingCtx.isColumn(SORT_COLUMN.SAKTYPE)}
                                    direction={sortingCtx.getOrder()}
                                    onClick={() =>
                                        sortingCtx.requestSort(SORT_COLUMN.SAKTYPE)
                                    }
                                >
                                    Sakstype
                                </TableSortLabel>
                            </TableCell>
                        </Hidden>
                        <Hidden mdDown>
                            <TableCell className={classes.columnHeaderRightAligned}>
                                <TableSortLabel
                                    active={sortingCtx.isColumn(SORT_COLUMN.OPPRETTET)}
                                    direction={sortingCtx.getOrder()}
                                    onClick={() =>
                                        sortingCtx.requestSort(SORT_COLUMN.OPPRETTET)
                                    }
                                >
                                    Opprettet
                                </TableSortLabel>
                            </TableCell>
                        </Hidden>
                        <Hidden mdDown>
                            <TableCell className={classes.columnHeaderRightAligned}>
                                <TableSortLabel
                                    active={sortingCtx.isColumn(SORT_COLUMN.OPPDATERT)}
                                    direction={sortingCtx.getOrder()}
                                    onClick={() =>
                                        sortingCtx.requestSort(SORT_COLUMN.OPPDATERT)
                                    }
                                >
                                    Oppdatert
                                </TableSortLabel>
                            </TableCell>
                        </Hidden>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {pageSaker.map((sak) => (
                        <TableRow
                            key={sak.id}
                            component={Link} to={"/sak/" + sak.id}
                        >
                            <TableCell className={styles.titleRow}>
                                {sak.tittel}
                            </TableCell>
                            <TableCell className={styles.omradeRow}>
                                {getOmraadeString(
                                    sak.nasjonal,
                                    sak.fylker,
                                    sak.kommuner
                                )}
                            </TableCell>

                            <TableCell className={styles.deadlineRow}>

                                {isThemeBreakpointDownSM ? (
                                    <div className={styles.deadlineWithStatusIcon}>
                                        <span className={sak.frist && sak.frist >= todayISOString() ? styles.cellValueNumeric : styles.cellValueNumericStrikeThrough}>
                                            {dateFormatConverter(sak.frist, true)}
                                        </span>
                                        <Dot status={sak.status}/>
                                    </div>
                                ) : (
                                    <div className={sak.frist && sak.frist >= todayISOString() ? styles.cellValueNumeric : styles.cellValueNumericStrikeThrough}>
                                        {dateFormatConverter(sak.frist)}
                                    </div>
                                )}
                            </TableCell>

                            <Hidden smDown>
                                <TableCell className={styles.statusRow}>
                                    <div className={styles.statusWithStatusIcon}>
                                        {sak.status !== sakStatus.ingen && sak.status}
                                        <Dot status={sak.status} />
                                    </div>
                                </TableCell>
                            </Hidden>
                            <Hidden smDown>
                                <TableCell className={styles.areaCell}>
                                    <div className={styles.cellValueNumeric}>
                                        {sak.areal !== undefined && sak.areal}
                                    </div>
                                </TableCell>
                            </Hidden>
                            <Hidden mdDown>
                                <TableCell className={styles.sakstypeCell}>
                                    {sak.sakstype != null && (sak.sakstype.replaceAll(";;", ", "))}
                                </TableCell>
                            </Hidden>
                            <Hidden mdDown>
                                <TableCell className={styles.opprettetCell}>
                                    <div className={styles.cellValueNumeric}>
                                        {dateFormatConverter(sak.opprettet)}
                                    </div>
                                </TableCell>
                            </Hidden>
                            <Hidden mdDown>
                                <TableCell className={styles.oppdatertCell}>
                                    <div className={styles.cellValueNumeric}>
                                        {dateTimeFormatConverter(sak.oppdatert)}
                                    </div>
                                </TableCell>
                            </Hidden>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {saker.length === 0 && <NoSakerFoundMessage />}
        </div>
    );
};

export default SakerDataGrid;
