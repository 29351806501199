import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {ADMIN_NEW_SAK_PATH, API_STATISTIKK_PATH, ERROR_PATH,} from "../../../constants/urls";
import {CircularProgress, IconButton, Menu, MenuItem} from "@material-ui/core";
import * as Ikoner from "@material-ui/icons";
import ErrorPage from "../../common/errorPage/ErrorPage";
import { UseKoordinatorContext } from "../../../context/KoordinatorContext";
import styles from "./adminMenu.module.css";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";

type Props = {
    kronologi?: string;
    visTommeRader?: boolean;
};

const AdminMenu = (props: Props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const history = useHistory();
    const token = UseKoordinatorContext().token;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const { logout } = useAuth0();

    if (error) {
        return <ErrorPage />;
    }
    if (isLoading) {
        return <CircularProgress className={styles.progress} />;
    }

    function getAttachmentFilename(res: Response, defaultName: string) {
        let filename = res.headers
            .get("content-disposition")?.split(";")
            .find(n => n.includes('filename='))?.replace('filename=', '').trim();
        return filename ? filename : defaultName;
    }

    function downloadBlob(blob: Blob, filename: string) {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', filename);
        link.download = filename;
        link.type = blob.type

        document.body.appendChild(link);

        link.click();

        //clean-up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    function getApiPath() {
        let krono = props.kronologi ? props.kronologi : "MND";
        let tomme = props.visTommeRader ? props.visTommeRader : false;

        return API_STATISTIKK_PATH + "?kronologi=" + krono + "&tommeRader=" + tomme;
    }

    function handleOnClickStatistikk() {

        setError(false);
        setIsLoading(true);

        let filename: string = "";
        let path = getApiPath();

        fetch(path, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                accept: `*/*`
            },
        }).then((res) => {
            if (res.ok) {
                filename = getAttachmentFilename(res, "ukjent.xlsx");
                return res.blob();

            } else {
                throw new Error(res.statusText);
            }
        }).then((blob) => {
            downloadBlob(blob, filename);

        }).catch((error) => {
            console.log(error);
            setError(true);
            history.push(ERROR_PATH);

        }).finally(() => {
            setIsLoading(false);
        });
    }

    function handleMenuDownloadStatistikk() {
        handleMenuClose();
        handleOnClickStatistikk()
    }

    function handleOnClickNySak() {
        handleMenuClose();
        history.push(ADMIN_NEW_SAK_PATH);
    }

    function handleLogout() {
        handleMenuClose();
        logout({ returnTo: window.location.origin });
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div className={styles.menu}>
            <IconButton
                // id="positioned-button"
                // aria-controls={open ? 'positioned-menu' : undefined}
                // aria-haspopup="true"
                // aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuButtonClick}
            >
                <Ikoner.Menu />
            </IconButton>
            <span className={styles.menuText}
                  onClick={handleMenuButtonClick}
            >
                Meny
            </span>
            <Menu
                // id="positioned-menu"
                // aria-labelledby="positioned-button"
                PaperProps={{
                    style: {
                        width: 'fit-content',
                    },
                }}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{vertical: 'top', horizontal: 'left',}}
                transformOrigin={{vertical: 'top',horizontal: 'left',}}
            >
                <MenuItem
                    onClick={handleOnClickNySak}
                >Ny sak</MenuItem>
                <MenuItem
                    onClick={handleMenuDownloadStatistikk}
                >Last ned statistikk</MenuItem>
                <MenuItem
                    onClick={handleLogout}
                >Logg ut</MenuItem>
            </Menu>
        </div>
    );
};

// export default withAuthenticationRequired(AdminMenu, {
//     onRedirecting: () => <CircularProgress />,
// });

export default withAuthenticationRequired(AdminMenu);
