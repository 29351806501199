import React from "react";
import SakerOverview from "./components/common/sakerOverview/SakerOverview";
import "../src/styles/App.css";

const PublicMainPage = () => {
    return (
        <div className={"publicMain"}>
            <SakerOverview />
        </div>
    );
};

export default PublicMainPage;
