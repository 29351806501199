import React from "react";
import { Button, makeStyles } from "@material-ui/core";

type Props = {
    title: string;
    url: string;
    icon?: any;
    color?: string;
};

const useStyles = makeStyles({
    button: {
        root: {
            "&:hover, &:focus": {
                boxShadow: "none",
            },
        },
    },
});

const ButtonWithLink = (props: Props) => {
    const classes = useStyles();

    return (
        <a
            href={props.url}
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: "none" }}
        >
            <Button
                classes={{ root: classes.button }}
                style={{ backgroundColor: props.color }}
                endIcon={props.icon}
            >
                {props.title}
            </Button>
        </a>
    );
};

export default ButtonWithLink;
