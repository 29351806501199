import React from "react";
import styles from "./informationBox.module.css";
import { Grid } from "@material-ui/core";
import { useNameMapping } from "../../../context/NameMappingContext";
import Dot from "../sakerDataGrid/Dot";
import {sakStatus} from "../../../constants/enum";
type Props = {
    frist?: string;
    areal?: number;
    nasjonal: boolean;
    fylker: number[];
    kommuner?: number[];
    status: sakStatus;
};

const InformationBox = (props: Props) => {
    return (
        <div className={styles.container}>
            <span className={styles.deadline}>
                <p className={styles.heading}>Svarfrist </p>
                <p className={styles.date}>
                    {" "}
                    {props.frist ? props.frist : "-"}
                </p>
            </span>

            <Grid container className={styles.metaInformation}>
                <Grid item xs={3} className={styles.content}>
                    <p className={styles.heading}>Areal (daa)</p>{" "}
                    {props.areal ? (
                        <p className={styles.subtitle}>{props.areal}</p>
                    ) : (
                        <p className={styles.subtitle}>-</p>
                    )}
                </Grid>

                <Grid item xs={5} className={styles.content}>
                    <p className={styles.heading}>Område</p>
                    <p className={styles.subtitle}>
                        {" "}
                        {useNameMapping().getOmraadeTekst(
                            props.nasjonal,
                            props.fylker,
                            props.kommuner
                        )}{" "}
                    </p>
                </Grid>

                <Grid item xs={4} className={styles.content}>
                    <p className={styles.heading}>Status</p>{" "}
                    <div className={styles.subtitleStatus}>
                        <p className={styles.subtitle}>{props.status}</p>
                        <Dot status={props.status} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default InformationBox;
