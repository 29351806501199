import React from "react";
import KantegaLogo from "../../../icons/KantegaLogo";
import logo from "./logo.svg";

import styles from "./footer.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import {FNF_NETT_URL} from "../../../constants/urls";

const Footer = () => {
    const { isAuthenticated } = useAuth0();

    function conditionalStyling() {
        if (isAuthenticated) {
            return styles.footerKoordinator;
        } else {
            return styles.footer;
        }
    }

    return (
        <footer className={conditionalStyling()}>
            <div className={styles.footerContent}>
                <div className={styles.fnf}>
                    <h3 className={styles.heading}>Kontakt</h3>
                    <p className={styles.subtitle}>hoeringsradaren@fnf-nett.no</p>
                    <a
                        href={FNF_NETT_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.fnfLogo}
                    >
                        <img src={logo} alt=""/>
                        <p className={styles.subtitle}>
                            Forum for natur og friluftsliv
                        </p>
                    </a>
                </div>
                <div className={styles.supportedBy}>
                    <p className={styles.subtitle}>
                        FNF støttes av Miljødirektoratet og fylkeskommunene. FNF alene er ansvarlig for innholdet på
                        sidene og i Høringsradaren.
                    </p>
                </div>
                <a
                    href="https://kantega.no/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.kantega}
                >
                    <p className={styles.subtitle}>Utviklet av</p>
                    <KantegaLogo/>
                </a>
            </div>
        </footer>
    );
};

export default Footer;
