import React from "react";
import styles from "./errorPage.module.css";
import ugler from "./ugler.svg";

const ErrorPage = () => {
    return (
        <div className={styles.content}>
            <h1 className="main-heading">
                Beklager, her er det ugler i mosen!
            </h1>
            <p className={styles.subtitle}> Prøv igjen om en liten stund...</p>
            <img src={ugler} alt="Tre ugler som sitter i mosen" />
        </div>
    );
};

export default ErrorPage;
