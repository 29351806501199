enum sakStatus {
    horing = "På høring",
    behandles = "Behandles",
    vedtatt = "Vedtatt",
    ingen = "Ingen status",
}

enum QUERY_PARAMS {
    FYLKE = "fylke",
    KOMMUNE = "kommune",
    SAKSNIVAA = "saksnivaa",
    SVARFRIST = "svarfrist",
    FRITEKST = "fritekst",
}

enum CONFLICT_TYPE {
    DUPLICATE_SAK = "DUPLICATE_SAK",
    DUPLICATE_LINK = "DUPLICATE_LINK",
}

export { sakStatus, QUERY_PARAMS, CONFLICT_TYPE };
